.text-sidebar {
  z-index: -1;
  padding: 100px 6vw;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 3rem;
  background-color: #000;
  border-right: 1px solid #fff;
  cursor: default;
}

header {
  display: flex;
  flex-direction: column;
}

.start-camera {
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  padding-right: var(--window-padding);
  position: absolute;
  overflow: hidden;
}

.start-camera svg {
  position: relative;
  max-width: 70vh;
  webkit-animation: spin 180s linear infinite;
  -moz-animation: spin 180s linear infinite;
  -ms-animation: spin 180s linear infinite;
  -o-animation: spin 180s linear infinite;
  animation: spin 180s linear infinite;
}

.start-camera .start-camera-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.start-camera button.start-camera-button {
  padding: 60px;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  color: #fff;
}

.start-camera[data-mobile-start-camera] {
  display: none;
}

@media only screen and (max-width: 598px) {
  .text-sidebar {
    padding: 30px 6vw;
    border: none;
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
  }

  .start-camera.start-camera {
    width: 100%;
    padding: 0 6vw;
    padding-bottom: 20px;
    left: 0;
    right: 0;
  }

  .start-camera svg {
    width: 100%;
  }

  .start-camera button.start-camera-button {
    padding: 40%;
  }
}

.start-camera.fade-out {
  opacity: 0;
}

.camera-overlay {
  animation-name: fade-in;
  animation-fill-mode: forwards;
  overflow: hidden;
  position: relative;
}

@-webkit-keyframes spin /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 992px) {
  .text-sidebar,
  .camera-wrapper {
    width: 100%;
  }
}
