.aura-cluster {
  opacity: 0;
  animation: fade-in-aura ease-in-out 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
  -webkit-animation: fade-in-aura ease-in-out 3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fade-in-aura ease-in-out 3s;
  -moz-animation-iteration-count: 1;
  -moz-animation-fill-mode: forwards;
  -moz-animation-delay: 0.6s;
}

.aura-cluster svg {
  pointer-events: none;
  position: absolute;
  transition: 0.1s ease-in all;
  z-index: 0;
  filter: url(#wavy) blur(60px);
  -webkit-filter: url(#wavy) blur(60px);
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
}

.aura-cluster svg.aura-mobile {
  filter: url(#wavy) blur(30px);
  -webkit-filter: url(#wavy) blur(30px);
  animation: fade-in-aura-mobile ease-in-out 3s;
  -webkit-animation: fade-in-aura-mobile ease-in-out 3s;
  -moz-animation: fade-in-aura-mobile ease-in-out 3s;
}

@keyframes fade-in-aura {
  100% {
    opacity: 0.9;
  }
}
