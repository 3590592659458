.camera-wrapper {
  display: flex;
  width: 75vh;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 598px) {
  .camera-wrapper {
    width: 100vw;
    height: 100dvh;
    align-items: end;
  }
}

video {
  opacity: 0.8;
  background-color: #000;
  display: block;
  object-fit: cover;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  margin: 0;
  padding: 0;
}

/* for debugging */
.bounding-box {
  z-index: 1;
  border: 1px solid #fff;
  position: absolute;
}

#captured-webcam-photo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.countdown-numbers {
  display: block;
}

.countdown-numbers > div {
  display: inline;
}

.screen-flash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  animation: fade-out ease-in-out 0.5s;
  pointer-events: none;
}
