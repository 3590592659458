.shutter-button-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 99;
  /* make into var */
  width: 75vh;
  /* make border into var too tbh */
  border-top: 1px solid #fff;
  padding-bottom: var(--window-padding);
  padding-right: var(--window-padding);
  display: flex;
  /* useful only if want to spin icon on sibling hover */
  flex-direction: row-reverse;

  .shutter-button-icon {
    width: 25%;
    border-right: 1px solid #fff;
    flex-shrink: 0;

    > svg {
      animation-name: spin;
      animation-duration: 10s;
      animation-timing-function: linear;
      animation-fill-mode: backwards;
      animation-play-state: paused;
      animation-iteration-count: infinite;
      transition: 1s ease-in-out all;
    }
  }

  .shutter-button {
    transition: 0.1s ease-in all;
    border: 1px solid #fff;
    flex-grow: 1;
    border-radius: 100%;
  }
}

.waiting-for-photo {
  > .shutter-button {
    transition: 0.1s ease-in all;
    border: none;
  }

  > .shutter-button-icon svg {
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
}

/* spin icon on hover and missing camera permissions */
.shutter-button:hover + .shutter-button-icon svg,
.no-camera-permission + .shutter-button-icon svg {
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@media screen and (max-width: 598px) {
  .shutter-button-wrapper {
    padding-bottom: 0;
    padding-right: 0;
    width: 100vw;
    left: 0;
    border-bottom: 1px solid #fff;
  }
}
