.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background: #000;
  color: #fff;
  z-index: 999;
  transition: opacity 2s ease-in-out;
}

.spinning-text-container {
  position: relative;
  width: 28vh;
  height: 28vh;
  animation: loading-spin 44s linear infinite;
  transition: opacity 2s ease-in-out;
  opacity: 0;
}

.spinning-text-container.force-fade-out {
  transition: opacity 1s ease-in-out;
  animation-play-state: paused;
}

.spinning-character {
  position: absolute;
  left: 50%;
  transform-origin: 0 14vh;
}

@keyframes loading-spin {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
