/* todo share button styles */
.button-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 99;
  /* make border into var too tbh */
  border-top: 1px solid #fff;
  display: flex;
  padding-bottom: var(--window-padding);
}

.button-wrapper .button-icon svg {
  animation-name: spin;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
  animation-play-state: paused;
  animation-iteration-count: infinite;
  transition: 1s ease-in-out all;
}

.export-controls-wrapper {
  position: fixed;
  left: 0;
  width: calc(100vw - 75vh);
  flex-direction: column;
  padding-left: var(--window-padding);
  background-color: rgba(0, 0, 0, 0.1);
}

.export-controls {
  display: flex;
}

.export-controls .control-wrapper {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  padding: 0 2rem;
}

.export-controls button:not([disabled]):hover + .button-icon svg {
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@media only screen and (max-width: 598px) {
  .button-wrapper.export-controls-wrapper {
    width: 100%;
    padding: 0;
  }
}

.control-wrapper:last-child {
  border-left: 1px solid #fff;
}

.control-wrapper .button-icon {
  /* vars pleaseee, width(=height) - retake photo padding and font size and a lil */
  width: calc(10vw - 0.5rem - 0.64rem - 0.675rem);
  padding: 0.45rem;
}

.control-wrapper button {
  flex: 1;
}

.control-wrapper button[disabled],
.control-wrapper button[disabled] + .button-icon {
  opacity: 0.5;
}

.export-controls-wrapper .thin-container {
  padding: 0.5rem;
  text-align: center;
}

.retake-photo-button {
  border-top: 1px solid #fff;
}

.gallery-note {
  border-bottom: 1px solid #fff;
}

@media only screen and (max-width: 598px) {
  .export-controls .control-wrapper {
    padding: 0;
  }

  .control-wrapper .button-icon {
    display: none;
  }

  .control-wrapper button,
  .export-controls-wrapper .thin-container {
    padding: 1.325rem;
  }
}
